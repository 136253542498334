
import React from 'react'
import logo from '../../../../images/luday_logo.png'
/* This example requires Tailwind CSS v3.0+ */
import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { NavLink } from "react-router-dom";
import * as ROUTE from '../../../../constants/routes'

const Navigation = () => {

const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="isolate bg-white">
      <div className="px-6 pt-4 lg:px-8">
        <nav className="flex items-center justify-between" aria-label="Global">
          <div className="flex lg:flex-1">
            <NavLink to={ROUTE.HOME} className="-m-1.5 p-1.5">
              <span className="sr-only">Luday AB</span>
              <img className="w-30 bg-[url('images/luday_logo_line.png')] hover:bg-none" src={logo} alt="" />
            </NavLink>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            <NavLink to={ROUTE.HOME} className="text-lg font-semibold leading-6 text-gray-900">
              Home
            </NavLink>
            <NavLink to={ROUTE.ABOUT} className="text-lg font-semibold leading-6 text-gray-900">
              About us
            </NavLink>
            <NavLink to={ROUTE.SERVICES} className="text-lg font-semibold leading-6 text-gray-900">
              Services
            </NavLink>
            <NavLink to={ROUTE.BLOG} className="text-lg font-semibold leading-6 text-gray-900">
              Blogs
            </NavLink>
            <NavLink to={ROUTE.CONTACT} className="text-lg font-semibold leading-6 text-gray-900">
              Contact us
            </NavLink>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <button className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#3BA4E9] rounded-lg hover:bg-[#01015D] focus:outline-none focus:ring focus:ring-[#01015D] focus:ring-opacity-80">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
          </svg>

            <NavLink to={ROUTE.QUOTE} className="mx-1">Get a Quote</NavLink>
          </button>
          </div>
        </nav>
        <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <Dialog.Panel focus="true" className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="w-30 bg-[url('images/luday_logo_line.png')] hover:bg-none" src={logo} alt="Luday AB logo" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <NavLink to={ROUTE.HOME} className="-mx-3 block rounded-lg py-2 px-3 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-400/10" onClick={() => setMobileMenuOpen(false)}>
                    Home
                  </NavLink>
                  <NavLink to={ROUTE.ABOUT} className="-mx-3 block rounded-lg py-2 px-3 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-400/10" onClick={() => setMobileMenuOpen(false)}>
                    About us
                  </NavLink>
                  <NavLink to={ROUTE.SERVICES} className="-mx-3 block rounded-lg py-2 px-3 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-400/10" onClick={() => setMobileMenuOpen(false)}>
                    Services
                  </NavLink>
                  <NavLink to={ROUTE.BLOG} className="-mx-3 block rounded-lg py-2 px-3 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-400/10" onClick={() => setMobileMenuOpen(false)}>
                    Blogs
                  </NavLink>
                  <NavLink to={ROUTE.CONTACT} className="-mx-3 block rounded-lg py-2 px-3 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-400/10" onClick={() => setMobileMenuOpen(false)}>
                    Contact us
                  </NavLink>
                </div>
                <div className="py-6">
                  <button className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#3BA4E9] rounded-lg hover:bg-[#01015D] focus:outline-none focus:ring focus:ring-blue-[#01015D] focus:ring-opacity-80">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                    </svg>

                      <NavLink to={ROUTE.QUOTE} className="mx-1" onClick={() => setMobileMenuOpen(false)}>Get a Quote</NavLink>
                  </button>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </div>
    </div>
  )
};

export default Navigation;
 