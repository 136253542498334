
import React from 'react';
import Banner from '../../components/Banner/Banner'
import ContactSidebar from '../../components/Sidebars/ContactSidebar'
import ContactForm from '../../components/Forms/ContactForm'
import Maps from '../../components/Map/Map'
import { useDocumentTitle, useScrollTop } from '../../hooks';


const Contact = () => {
useDocumentTitle('Contact Us | Luday AB');
  useScrollTop();
    return (
        <>
            < Banner  titleText='Contact Us' subtitleText='Contact'/>

            <section className="bg-white">
                <div className="container max-w-7xl px-6 py-10 mx-auto">
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl ">Feel free to send us a message.</p>
                
                    <div className='flex flex-col-reverse md:flex-row mt-20'>
                        <div className='w-auto md:w-2/5 m-5 md:m-10'>

                            < ContactSidebar/>

                        </div>
                        <div className='w-auto md:w-3/5 m-5 md:m-10'>

                            < ContactForm/>

                        </div>
                    </div>
                </div>
            </section>

            < Maps/>
        </>
    )
}
export default Contact;
 