
import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import * as ROUTE from '../../../constants/routes';
import * as CONSTANT from '../../../constants/constants';


const BlogDetailContent = () => {

  const {slug} = useParams();
  const [blog, fetchBlog] = useState([]);
  // const [loading, setLoading] = useState(false);

  const getData = () => {
    // setLoading(true)
  fetch(`${ROUTE.BLOGS_API}/sblog/${slug}`)
    .then((res) => res.json())
    .then((res) => {
      fetchBlog(res.data[0])
      
      // setLoading(false)
    })
  }
  useEffect(() => {
    getData()
  }, []);

  return (
	<>
		<div className="pb-10">
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl ">{blog.title}</h1>
        </div> 
		<div className="">
			<img className="lazy rounded-md" src={`${CONSTANT.IMAGE_STORE}/${blog.image_path}`} alt={blog.title}/>
		</div>
		<div className="h-auto mt-5" dangerouslySetInnerHTML={{ __html: blog.content }} />
          
	</>
  );
}

export default BlogDetailContent;
 