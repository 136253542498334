
import React from 'react';
import 'tw-elements';

function Testimonials() {
  const Testimonies = [
        {
            id: 0,
            title: 'Good Product Delivery',
            content: 'I am absolutely happy with the service provided by Luday AB. They designed a very nice and working website for me that I use for selling farm produce.',
            name: "Daniel Barry"
        },
        {
            id: 1,
            title: 'Redesigned and added new features to my website',
            content: 'I am  happy that the e-commerce feature was added to my website. Thank you Luday AB.',
            name: "Best Excel"
        },
        {
            id: 2,
            title: 'Good and working app',
            content: 'The application that was delivered to me after they finished  with it worked exactly as expected. So far so good no error has been reported by any user',
            name: "Uwem Life"
        }
    ]
    return (
        <section className="bg-white">
            <div className="container max-w-7xl px-6 py-10 mx-auto">
                <h2 className="text-lg font-semibold leading-8 tracking-tight text-[#3BA4E9]">Testimonials</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-20">What people say about us</p>
                <div id="carouselDarkVariant" className="carousel slide carousel-fade carousel-dark relative" data-bs-ride="carousel">
                    <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
                        {Testimonies.map((dash) => (
                        <div key={dash.name}>
                        <button data-bs-target="#carouselDarkVariant" data-bs-slide-to={dash.id} className={`${dash.id === 0 ? "active" : ""}`} aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        ))}
                    </div>
                    <div className="carousel-inner relative w-full overflow-hidden">

                        {Testimonies.map((testimoney) => (
                        <div key={testimoney.id} className={`carousel-item relative float-left w-full ${testimoney.id === 1 ? "active" : ""}`}>
                            <div className="py-4 px-8 bg-white shadow-lg rounded-lg my-20">
                                <div className="flex justify-center md:justify-end -mt-16">
                                    <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" src="https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"/>
                                </div>
                                <div>
                                    <h2 className="text-gray-800 text-2xl font-semibold">{testimoney.title}</h2>
                                    <p className="mt-2 text-gray-600">{testimoney.content}</p>
                                </div>
                                <div className="flex justify-end mt-4">
                                    <a href="#" className="text-xl font-medium text-indigo-500">{testimoney.name}</a>
                                </div>
                            </div>
                        </div>
                        ))}

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonials;
