
import React from 'react';
import Client1 from '../../images/clients/afj-logo.png'
import Client2 from '../../images/clients/dowhile-logo.png'
import Client3 from '../../images/clients/white_logo_transparent_background.png'
import Client4 from '../../images/clients/bestdeal-logo.png'
import Client5 from '../../images/clients/volvo-grouplogo.png'
import Client6 from '../../images/clients/logo_notag_neg.png'

const Clients=()=>{
    const features = [
        {
            name: 'AFJ FARMS',
            alt: 'Afj farms',
            icon: Client1,
            website: 'https://afjfarms.com'
        },
        {
            name: 'Do While',
            alt: 'Do While',
            icon: Client2,
            website: 'https://dowhile.se/'
        },
        {
            name: 'COMDOITY',
            alt: 'Comdoity',
            icon: Client3,
            website: 'https://comdoity.com'
        },
        {
            name: 'BESTDEALNAIJA',
            alt: 'BestDealNaija',
            icon: Client4,
            website: 'https://bestdealnaija.com/'
        },
        {
            name: 'VolvoGroup',
            alt:"Volvo Group",
            icon: Client5,
            website: "https://www.volvogroup.com/en/",
        },
        {
            name: 'VolvoGroup',
            alt:"Volvo Group",
            icon: Client6,
            website: "https://www.sigma.se/",
        }
    ]
    return(
        <section className="bg-gray-900">
          <div className="py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
                <div className="mb-10">
                    <h2 className="text-lg font-semibold leading-8 tracking-tight text-[#3BA4E9]">Our Clients</h2>
                    <p className="mt-2 mb-3 text-3xl font-bold tracking-tight text-white sm:text-4xl">Past and current Clients</p>
                </div>
            
                <div className='flex w-full'>
                    <div className=' justify-center m-auto item-center'>
                        <div className="flex flex-row flex-wrap mx-auto text-center">
                            {features.map((feature) => (
                            <a href={feature.website} key={feature.name} className="flex m-7" target="_blank">
                                <img src={feature.icon} alt={feature.alt} className=' grayscale-0 hover:grayscale transition-all duration-500 w-52 h-14'/>
                            </a>
                            ))}
                        </div>
                    </div>
                </div>
          </div>
      </section>
    )
}

export default Clients
