
import React from 'react'
import { useForm } from 'react-hook-form';
import swal from 'sweetalert'
import * as ROUTE from '../../constants/routes';

const QuoteForm = () => {

	const {register, handleSubmit, reset, formState: { errors } } = useForm();

	const successAlert = (response) => {
		return(
		swal({
			title: "Info!",
			text: response,
			icon: "success"
		})              
		)
	}

	const submitForm = (data) => {
		const body = {
		name: data.name,
		email: data.email,
		mobile: data.phone,
		subject: data.subject +", "+ data.selected_industry,
		mssg: data.mssg
		// industry: data.selected_industry
		}

		console.log(body)

		const requestOptions = {
			method: "POST",
			headers: {
			'content-type': 'application/json'
			},
			body: JSON.stringify(body)
		}

		fetch(`${ROUTE.CONTACT_API}/contact/add`, requestOptions)
		.then(res => res.json())
		.then(data =>{
			console.log(data)
			successAlert(data.message)
		})
		.catch(err => console.log(err))

		reset()
	}
    
	const industries =[
		{
			id: 1,
			name: "Technology"
		},
		{
			id: 2,
			name: "Production"
		},
		{
			id: 3,
			name: "Marketing"
		},
		{
			id: 4,
			name: "Trade"
		},
		{
			id: 5,
			name: "Agriculture"
		},
		{
			id: 6,
			name: "Finance"
		},
		{
			id: 7,
			name: "Construction"
		},
		{
			id: 8,
			name: "Advertising"
		},
		{
			id: 9,
			name: "Investment"
		},
		{
			id: 10,
			name: "Mining"
		},
		{
			id: 11,
			name: "Retail"
		},
		{
			id: 12,
			name: "Transport"
		},
		{
			id: 13,
			name: "Health care"
		},
		{
			id: 14,
			name: "Real Estate"
		},
		{
			id: 15,
			name: "Engineering"
		},
		{
			id: 16,
			name: "Media"
		},
		{
			id: 17,
			name: "Law"
		},
		{
			id: 18,
			name: "Accounting"
		}
	]
    
  return (
	<>
		<div className="flex items-left justify-start bg-white bg-opacity-50 shadow-xl p-11 mt-5">
			<div className="mx-auto w-full max-w-lg">
				<p className="mt-3">Get started with talking with us on creating that software for your business</p>

				<form className="mt-10">
				<div className="">
					<div className='flex flex-col md:flex-row md:justify-between'>
						<div className="relative z-0 w-full md:w-1/2 md:pr-2 mb-5">
						<input type="text" name="name" 
        							className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" 
         							placeholder="" 
         							{...register("name", { required: true, maxLength: 25 })} />
								<label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Name</label>
        						{errors.name && <small className="text-red-500 text-xs italic">Your name is required</small>}
                              	{errors.name?.type === "maxLength" && <p style={{ color: "red" }}><small>Max characters should be 25 </small></p>}
						</div>
						<div className="relative z-0 w-full md:w-1/2 md:pl-2 mb-5">
							<input type="phone" name="phone" className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" placeholder="" 
							{...register("phone", { required: true, maxLength: 15 })} />
							<label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Phone</label>
							{errors.phone && <small className="text-red-500 text-xs italic">Your phone is required</small>}
							{errors.phone?.type === "maxLength" && <p style={{ color: "red" }}><small>Max characters should be 16 </small></p>}
						</div>
					</div>
					<div className="relative z-0 col-span-2 mb-5">
						<input 
							type="text" name="email" 
							className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" 
							placeholder="" 
							{...register("email", { required: true, maxLength: 50 })} />
						<label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Email</label>
						{errors.email && <small className="text-red-500 text-xs italic">Your e-mail is required</small>}
						{errors.email?.type === "maxLength" && <p style={{ color: "red" }}><small>Max characters should be 100 </small></p>}
					</div>
					<div className="relative z-0 col-span-2 mb-5">
						<select className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-3 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" {...register("selected_industry", { required: true })}>
							<option value="0">Click to Select</option>
							{industries.map((industry) => (
							<option key={industry.id} value={industry.id}>{industry.name}</option>
							))}
						</select>
						<label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Industy of business</label>
						{errors.selected_industry && <small className="text-red-500 text-xs italic">Select at least one industry</small>}
					</div>
					<div className="relative z-0 col-span-2 mb-5">
						<input 
							name="Subject" className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" 
							placeholder="" 
							{...register("subject", { required: true, maxLength: 25 })}></input>
						<label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Name of Software</label>
						{errors.subject && <small className="text-red-500 text-xs italic">Name of software is required</small>}
						{errors.subject?.type === "maxLength" && <p style={{ color: "red" }}><small>Max characters should be 80 </small></p>}
					</div>
					<div className="relative z-0 col-span-2 mb-10">
						<textarea name="message" rows="5" 
						className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" 
						placeholder="" 
						{...register("mssg", { required: true, maxLength: 255 })}></textarea>
						<label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Describe what you want this software to do</label>
						{errors.mssg && <small className="text-red-500 text-xs italic">Message is required</small>}
                        {errors.mssg?.type === "maxLength" && <p style={{ color: "red" }}><small>Max characters should be 255 </small></p>}
					</div>
				</div>
				<button type="submit" onClick={handleSubmit(submitForm)} className="mt-5 rounded-md bg-[#01015D] px-10 py-2 text-white">Send Request</button>
				</form>
			</div>
		</div>
	</>
    )
}

export default QuoteForm
 