
import React from 'react';
import Valuebg from "../../../images/bgimages/valuebg.png";
const values = [
        {
            name: 'Quality Service.',
            description:
            "We take pride in providing high value product and services that meets the demand of every client.",
            icon: 'M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z',
            class: 'w-6 h-6 bi bi-shield'
        },
        {
            name: 'Innovation',
            description: "We promote a work environment that boosts creative thinking and new ideas. This ensures we come up with sustainable products and services, adding value to the Organization and Customers at large.",
            icon: 'M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z',
        },
        {
            name: 'Customer Satisfaction',
            description: "We value our Customers and are committed to providing exceptional customer service by listening to the needs of every customer and delivering sustainable products and innovative solutions that add values to our customers.",
            icon: 'M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z" fill="white"></path> <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z',
            class: 'w-6 h-6 bi bi-award',
            icon2: "M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"
        },
        {
            name: 'Team work',
            description: "We enjoy collaborative effort and encourage every member of the organization to support each other in achieving a common goal. We rely on each other's Skills and are committed to the development of each team member.",
            icon: "M8 7.5a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1z",
            icon2: "M8 16a.5.5 0 0 1-.5-.5v-1.293l-.646.647a.5.5 0 0 1-.707-.708L7.5 12.793v-1.51l-2.053-1.232-1.348.778-.495 1.85a.5.5 0 1 1-.966-.26l.237-.882-1.12.646a.5.5 0 0 1-.5-.866l1.12-.646-.883-.237a.5.5 0 1 1 .258-.966l1.85.495L5 9.155v-2.31l-1.4-.808-1.85.495a.5.5 0 1 1-.259-.966l.884-.237-1.12-.646a.5.5 0 0 1 .5-.866l1.12.646-.237-.883a.5.5 0 1 1 .966-.258l.495 1.849 1.348.778L7.5 4.717v-1.51L6.147 1.854a.5.5 0 1 1 .707-.708l.646.647V.5a.5.5 0 0 1 1 0v1.293l.647-.647a.5.5 0 1 1 .707.708L8.5 3.207v1.51l2.053 1.232 1.348-.778.495-1.85a.5.5 0 1 1 .966.26l-.236.882 1.12-.646a.5.5 0 0 1 .5.866l-1.12.646.883.237a.5.5 0 1 1-.26.966l-1.848-.495-1.4.808v2.31l1.4.808 1.849-.495a.5.5 0 1 1 .259.966l-.883.237 1.12.646a.5.5 0 0 1-.5.866l-1.12-.646.236.883a.5.5 0 1 1-.966.258l-.495-1.849-1.348-.778L8.5 11.283v1.51l1.354 1.353a.5.5 0 0 1-.707.708l-.647-.647V15.5a.5.5 0 0 1-.5.5zm2-6.783V6.783l-2-1.2-2 1.2v2.434l2 1.2 2-1.2z"
        }
    ]
const Values = ()=>{
    
    return(
        
        <div className="relative p-4 py-28 bg-gray-100">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
                <div className="lg:col-start-2 md:pl-20 lg:pr-20">
                    <div className="mb-10">
                        <h2 className="text-lg font-semibold leading-8 tracking-tight text-[#3BA4E9]">Our Values</h2>
                        <p className="mt-2 mb-3 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">We believe in having a common vision as an Organization.</p>
                    </div>
                    <ul className="mt-10">
                        {values.map((value) => (
                        <li key={value.name} className="mt-10">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <div className="flex items-center justify-center w-12 h-12 text-white bg-[#3BA4E9] rounded-md">
                                        <svg width="20" height="20" fill="currentColor" className={value.class} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d={value.icon} fill="white">
                                            </path>
                                            <path d={value.icon2} fill="white"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <h5 className="text-lg font-bold leading-6 text-gray-900">
                                       {value.name}
                                    </h5>
                                    <p className="mt-2 text-base leading-6 text-gray-500">
                                        {value.description}
                                    </p>
                                </div>
                            </div>
                        </li>
                        ))}
                        
                    </ul>
                </div>
                <div className="relative mt-10 -mx-4 md:-mx-12 lg:mt-0 lg:col-start-1">
                    <img src={Valuebg} 
                        alt="illustration" className="relative w-auto mx-auto rounded shadow-lg sm:h-[calc(100%_-_2rem)] sm:self-end sm:rounded-tr-[30px] md:h-[calc(100%_-_4rem)] md:rounded-tr-[60px]"/>
                </div>
            </div>
        </div>

    )
}

export default Values;
 