
import React from 'react';
import { EnvelopeIcon, PhoneIcon, PhoneArrowDownLeftIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

const ContactSidebar = () => {
	return (
		<>
			<div className='flex flex-col h-auto from-gray-100 via-gray-100 to-gray-100 bg-gradient-to-br'>
				<div className="mx-8 mt-10 items-center justify-center w-auto h-auto rounded-xl group space-x-6 bg-white bg-opacity-50 shadow-sm hover:rounded-2xl">
					<ul className='my-9'>
						<li className='p-5 text-base md:text-lg tracking-tight flex flex-row items-center flex-wrap'>
							<div className='border-solid rounded-full border-2 p-1 border-[#01015D] m-2'>
								<EnvelopeIcon className='h-3 md:h-4 text-[#01015D] m-1 block'/>
							</div>
							<a href="mailto:info@luday.se">info@luday.se</a>
						</li>
					</ul>
				</div>
				<div className="m-7 ">
					<h5 className=' py-10 text-3xl font-bold tracking-tight text-gray-900 flex justify-center'>We are social</h5>
					<div className="mt-1 ">
						<div className="space-y-2  pr-5">
							<div className="space-y-4">
								<div className="mx-auto text-center mt-2">
									<ul className="flex justify-center mb-4 md:mb-0">
										<li>
											<a href='https://www.linkedin.com/company/luday/' target='_blank' className="flex justify-center items-center text-[#013289] hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out w-10 h-10" aria-label="Linkedin">
												<svg className="w-8 h-8 fill-current" viewBox="0 -8 17 30" xmlns="http://www.w3.org/2000/svg">
													<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
												</svg>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
};

export default ContactSidebar;
 