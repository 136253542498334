
import React from 'react'
import logo from '../../../images/luday_logo_w.png';
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import * as ROUTE from '../../../constants/routes'
import swal from 'sweetalert';

const Footer = () => {

  const {register, handleSubmit, reset, formState: { errors } } = useForm();

	const successAlert = (response) => {
		return(
		swal({
			title: "Info!",
			text: response,
			icon: "success"
		})              
		)
	}

	const submitForm = (data) => {
		const body = {
		email: data.email
		}

		const requestOptions = {
			method: "POST",
			headers: {
			'content-type': 'application/json'
			},
			body: JSON.stringify(body)
		}

		fetch(`${ROUTE.CONTACT_API}/newsletter/add`, requestOptions)
		.then(res => res.json())
		.then(data =>{
			console.log(data)
			successAlert(data.message)
		})
		.catch(err => console.log(err))

		reset()
	}

  return (
    <>     
       <footer className="bg-gray bg-gray-900 pt-10">
          <div className="container p-6 max-w-7xl mx-auto bg-gray">
              <div className="lg:flex">
                  <div className="w-full -mx-6 lg:w-2/5">
                      <div className="px-6">
                          <Link to={ROUTE.HOME}>
                              <img className="w-30 bg-[url('images/luday_logo_line.png')] hover:bg-none" src={logo} alt=""/>
                          </Link>

                          <p className="max-w-sm mt-2 text-gray-500 dark:text-gray-400">
                            Hisings Kärra<br/>
                            Västra Götalands län<br/>
                            42532 Sweden
                          </p>

                          <div className="flex mt-6 -mx-2">
                              <a href="https://www.linkedin.com/company/luday/"
                                  className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                                  aria-label="Twitter">
                                  <svg className="w-7 h-7 fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                          d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z">
                                      </path> 
                                  </svg>
                              </a>
                          </div>
                      </div>
                  </div>

                  <div className="mt-6 lg:mt-0 lg:flex-1">
                      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                            <div>
                                <h3 className="text-gray-700 uppercase font-semibold dark:text-white">Useful Links</h3>
                                <Link to={ROUTE.ABOUT} className="block mt-2 text-sm text-gray-400 hover:underline">Company</Link>
                                <Link to={ROUTE.SERVICES} className="block mt-2 text-sm text-gray-400 hover:underline">Services</Link>                       
                                <Link to={ROUTE.BLOG} className="block mt-2 text-sm text-gray-400 hover:underline">Blogs</Link>
                                <Link to={ROUTE.CONTACT} className="block mt-2 text-sm text-gray-400 hover:underline">Contact Us</Link>
                            </div>

                            <div>
                                <h3 className="text-gray-700 uppercase font-semibold dark:text-white">Our Services</h3>
                                <Link to={`${ROUTE.SERVICE_DETAILS}/web-development`} className="block mt-2 text-sm text-gray-400 hover:underline">Web Development</Link>
                                <Link to={`${ROUTE.SERVICE_DETAILS}/embedded-software-development`} className="block mt-2 text-sm text-gray-400 hover:underline">Embedded Software Development</Link>
                                <Link to={`${ROUTE.SERVICE_DETAILS}/digital-marketing`} className="block mt-2 text-sm text-gray-400 hover:underline">Digital Marketing</Link>
                                <Link to={`${ROUTE.SERVICE_DETAILS}/ui-ux-design`} className="block mt-2 text-sm text-gray-400 hover:underline">UI/UX Design</Link>
                                <Link to={`${ROUTE.SERVICE_DETAILS}/product-management`} className="block mt-2 text-sm text-gray-400 hover:underline">Product Management</Link>
                                <Link to={`${ROUTE.SERVICE_DETAILS}/data-analytics`} className="block mt-2 text-sm text-gray-400 hover:underline">Data Analytics</Link>
                            </div>
                            <form>
                                <div>
                                    <h3 className="text-gray-700 uppercase font-semibold dark:text-white">Newsletter</h3>
                                    <p className="text-gray-400">Keep in touch with the latest news and updates from Luday</p>
                                    
                                    <div className="flex relative mb-10 mt-5">
                                        <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                                            <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z">
                                                </path>
                                            </svg>
                                        </span>
                                        <input type="text" id="email-with-icon" className=" flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent" name="email" placeholder="Your email" {...register("email", { required: true, maxLength: 50 })}/>  
                                        
                                        <button className="rounded-r-md inline-flex  items-center px-3 border-t bg-white border-r border-b  border-gray-300 text-gray-500 shadow-sm text-sm" onClick={handleSubmit(submitForm)}>Send</button>
                                    </div>
                                    {errors.email && <small className="text-red-500 text-xs italic">Your e-mail is required</small>}
                                    {errors.email?.type === "maxLength" && <p style={{ color: "red" }}><small>Max characters should be 100 </small></p>}
                                </div>
                            </form>
                      </div>
                  </div>
              </div>

              <hr className="h-px my-6 bg-gray-200 border-none dark:bg-gray-700"/>

              <div>
                  <p className="text-center text-gray-500 dark:text-gray-400">© Luday AB 2023 - All rights reserved</p>
              </div>
          </div>
      </footer>
    </>
  )
}

export default Footer
 