
import React from 'react';
import Banner from '../../../components/Banner/Banner'
import Intro from '../../../components/Intro/Intro'
import Portfolio from '../../../components/Portfolio/Portfolio'
import AboutArticle from './AboutArticle'
import Value from './Value'
import { useDocumentTitle, useScrollTop } from '../../../hooks';



const About = () => {
  useDocumentTitle('About Us | Luday AB');
  useScrollTop();
  return (
	<div className="app">
    < Banner  titleText='About Us' subtitleText='About Us'/>
    < Intro  />
    < AboutArticle />
    < Value />
    < Portfolio />
	</ div>
  )
}
export default About;
