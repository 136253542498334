
import {React, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import {  BookOpenIcon, LightBulbIcon, ArrowRightIcon } from '@heroicons/react/20/solid'
import Aboutusimg from "../../images/bgimages/aboutus.png";
import * as ROUTE from '../../constants/routes';


const About = (color) => {
  const [openTab, setOpenTab] = useState(1);
   useEffect(() => {
    if (window.location.href.includes("/about")) {
            document.getElementById("ink").style.display = "none";
            document.getElementById("heading").style.display = "none";
        }
  }, []);
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-lg font-semibold leading-8 tracking-tight text-[#3BA4E9]" id="heading">About Us</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Who we are</p>
              <p className="my-6 text-lg leading-8 text-gray-600">
               We are an innovation driven ICT company committed to giving the best solution to any business need. Our goal is to develop user-friendly scalable software and mobile applications that meet the needs of our clients and improve business productivity.
              </p>
              <div className="flex">
                <div className="w-screen">
                  <ul className="flex list-none pt-5 flex-row border-b border-gray-300"role="tablist">
                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                      <a className={"text-sm pb-2 font-bold uppercase leading-normal flex items-center " +
                          (openTab === 1
                            ? "text-" + color + "-600 border-b-4 border-" + color + "-600"
                            : "text-blue-900 bg-white")
                        }
                        onClick={e => {
                          e.preventDefault();
                          setOpenTab(1);
                        }}
                        data-toggle="tab"
                        href="#link1"
                        role="tablist"
                      >
                        <BookOpenIcon className="h-5 w-5 text-[#3BA4E9]" aria-hidden="true" />Our Mission
                      </a>
                    </li>
                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                      <a
                        className={"text-sm pb-2 font-bold uppercase  leading-normal flex items-center " +
                          (openTab === 2
                            ? "text-" + color + "-600 border-b-4 border-" + color + "-600"
                            : "text-blue-900 bg-white")
                        }
                        onClick={e => {
                          e.preventDefault();
                          setOpenTab(2);
                        }}
                        data-toggle="tab"
                        href="#link2"
                        role="tablist"
                      >
                        <LightBulbIcon className="h-5 w-5 text-[#3BA4E9]" aria-hidden="true" />
                        Our Vision
                      </a>
                    </li>
                    
                  </ul>
                  <div className="relative flex flex-col min-w-full break-words bg-white w-full mb-6 text-blue-900 text-justify">
                    <div className="px-4 py-5 flex-auto">
                      <div className="tab-content tab-space">
                        <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                          <p>
                              Our mission is to provide quality and cutting edge technological solutions for small to large scale businesses and individuals
                          </p>
                        </div>
                        <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                          <p>
                              Our vision is to be an enabler in the success story of our customers and partners.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex justify-center lg:justify-start'>
                <Link to={ROUTE.ABOUT} id="ink" className='bg-[#01015D] hover:bg-[#3BA4E9] mt-4 py-3 px-16 text-white rounded-md font-semibold text-md tracking-wide flex items-center' > Read More <ArrowRightIcon className="h-5 w-5 text-white" aria-hidden="true" /></Link>
              </div>
            </div>
          </div>
          <img src={Aboutusimg} alt="Product screenshot" className="h-[28rem] md:h-[48rem] rounded-xl shadow-xl ring-1 ring-gray-400/10"/>
        </div>
        <div className='-mt-40 mr-5 md:mr-0 flex flex-col md:flex-row'>
          <div className='ml-5 md:ml-5 lg:-ml-10 w-full md:w-2/5 m-5 bg-white h-auto shadow-md'>
            <div className='bg-blue-400 h-3'></div>
              <h3 className="p-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Do you need a digital product?</h3>
              <p className='px-5'>We can complete the development of your digital product using the agile software development approach.. We are also experts at scaling already existing apps, assisting clients in making the transition from products to products that genuinely expand along with their user base.</p>
              <div className='m-5 flex justify-center lg:justify-start'>
                <Link to={ROUTE.QUOTE} className='bg-[#01015D] hover:bg-[#3BA4E9] mt-4 py-3 px-16 text-white rounded-md font-semibold text-md tracking-wide flex items-center' > Get a Quote <ArrowRightIcon className="h-5 w-5 text-white" aria-hidden="true" /></Link>
              </div>
          </div>
          <div className='w-full md:w-3/5 m-5  bg-white h-auto shadow-md'>
            <div className='bg-blue-400 h-3'></div>
            <h3 className="p-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Need a cross-functional development team?</h3>
              <p className='px-5'>Our cross-functional, agile teams of software engineers and designers are committed to achieving your business objectives. The development team will offer guidance along the way, helping you decide on a course of action, adapt to shifting conditions, and transfer their knowledge so you can advance on your own.<br/><br/></p>
              <div className='m-5 flex justify-center lg:justify-start'>
                <Link to={ROUTE.CONTACT} className='bg-[#01015D] hover:bg-[#3BA4E9] mt-4 py-3 px-16 text-white rounded-md font-semibold text-md tracking-wide flex items-center' > Contact Us <ArrowRightIcon className="h-5 w-5 text-white" aria-hidden="true" /></Link>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
 