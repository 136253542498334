
import React from 'react';
import Slider from '../../components/Hero/Slider'
import Intro from '../../components/Intro/Intro'
import ServicesContainer from '../../components/Services/ServicesContainer'
import Counter from '../../components/Counter/Counter'
import Deals from '../../components/Deals/Deals'
import Client from '../../components/Clients/Client'
import Testimonials from '../../components/Testimonials/Testimonials'
import { useDocumentTitle, useScrollTop } from '../../hooks';


function Home() {
  useDocumentTitle('Home | Luday AB');
  useScrollTop();
  return (
	<div className="app">
		< Slider  />
    < Intro  />
    < ServicesContainer  />
    < Counter  />
    < Client  />
    < Testimonials  />
    < Deals  />
	</ div>
  )
}
export default Home;
