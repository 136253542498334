
import React from 'react'

const Map = () => {
    
  return (
	<>
		<div className="flex items-left justify-start bg-white bg-opacity-50 mt-5">
			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d37999524.94538863!2d-18.3208108050181!3d54.45751927452855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465cb2396d35f0f1%3A0x22b8eba28dad6f62!2sSweden!5e0!3m2!1sen!2sus!4v1677573908333!5m2!1sen!2sus" className='w-full min-h-screen'></iframe>
		</div>
	</>
    )
}

export default Map
 