
import {React, useEffect} from 'react';
import PropType from 'prop-types';
import { useParams } from 'react-router-dom';

const Banner = ({ titleText, subtitleText }) => {
    const {slug} = useParams();
    useEffect(() => {
    if (window.location.href.includes("/blog-details")) {
            document.getElementById("heading1").style.display = "none";
            document.getElementById("heading2").style.display = "block";
        }
  }, []);
    return (
        <section>
            <div className="bg-[#01015D] py-9" >
                <div className="lg:pr-8 lg:pt-4 ml-9 ">
                    <div className="lg:max-w-lg">
                        <nav className="flex items-center justify-between">
                            <div className="flex items-center">
                                <a href="/" className="text-gray-500 hover:text-gray-600 mr-6">Home</a>
                                <svg className="fill-current text-gray-500 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10.707 7.05L10 6.343 4.343 12l1.414 1.414L10 9.172l4.243 4.242L15.657 12z"/></svg>
                                <p className="text-gray-500 mr-6">{subtitleText}</p>
                            </div>
                        </nav>
                        <h1 className="mt-2 text-3xl leading-8 text-gray-300 font-bold tracking-tight" id="heading1">{titleText}</h1>
                        <p className="mt-2 text-3xl leading-8 text-gray-300 font-bold tracking-tight hidden" id="heading2">{titleText}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

Banner.defaultProps = {
    titleText: 'Luday AB'
};

Banner.propTypes = {
    titleText: PropType.string,
    subtitleText: PropType.string
};

export default Banner
 