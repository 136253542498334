
import React from 'react'
import Video from '../../videos/introvid.mp4'
import * as ROUTE from '../../constants/routes';
import { Link } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';

/*This Component requires react-simple-Typewriter, to install run the following command:
    - npm install --force react-simple-typewriter

*/

const Slider = () => {
    const word = [
        'design',
        'develop',
        'customize',
        'maintain'
    ];

  return (
    <>
        <div className='relative min-h-screen overflow-hidden'>
            <div className='relative -z-50 bg-black opacity-95 min-h-screen'>
                
                <video className='absolute w-auto min-w-full min-h-full max-w-none object-contain -translate-x-1/3 md:-translate-x-0' autoPlay loop muted playsInline>
                    <source src={Video} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
                
            </div>
            <div className="absolute top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center">
                <div className='md:w-2/3 m-auto flex flex-col px-3 md:p-0'>
                    <h1 className="text-white text-4xl md:text-7xl font-bold text-center text-shadow-2xl">Get a software solution to automate your business!</h1>
                    <p className='text-gray-400 text-center text-shadow-lg my-10 text-2xl md:text-3xl'>We <span className='text-[#3BA4E9]'>
                                <Typewriter
                                    words={word}
                                    loop = {0}
                                    cursor
                                    cursorStyle='|'
                                    typeSpeed={70}
                                    deleteSpeed={50}
                                    delaySpeed={1000}
                                    
                                />
                            </span>software for operating systems, business applications, mobile devices, and more. </p>
                    <Link to={ROUTE.SERVICES} className="px-8 py-4 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#3BA4E9] rounded-lg hover:bg-[#01015D] m-auto text-center">
                        Get Started
                    </Link>
                </div>
            </div>
        </div>
        {/* <div id="carouselDarkVariant" className="carousel slide carousel-fade carousel-dark relative" data-bs-ride="carousel">
            <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
                <button data-bs-target="#carouselDarkVariant" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button data-bs-target="#carouselDarkVariant" data-bs-slide-to="1" aria-label="Slide 1"></button>
                <button data-bs-target="#carouselDarkVariant" data-bs-slide-to="2" aria-label="Slide 1"></button>
            </div>
            <div className="carousel-inner relative w-full overflow-hidden">
                <div className="carousel-item active relative float-left w-full">
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(19).webp" className="block w-full" alt="Motorbike Smoke"/>
                    <div className="carousel-caption hidden md:block absolute text-center">
                        <h5 className="text-xl">First slide label</h5>
                        <p>Some representative placeholder content for the first slide.</p>
                    </div>
                </div>
                <div className="carousel-item relative float-left w-full">
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(35).webp" className="block w-full" alt="Mountaintop"/>
                    <div className="carousel-caption hidden md:block absolute text-center">
                        <h5 className="text-xl">Second slide label</h5>
                        <p>Some representative placeholder content for the second slide.</p>
                    </div>
                </div>
                <div className="carousel-item relative float-left w-full">
                <img src="https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(40).webp" className="block w-full" alt="Woman Reading a Book"/>
                    <div className="carousel-caption hidden md:block absolute text-center">
                        <h5 className="text-xl">Third slide label</h5>
                        <p>Some representative placeholder content for the third slide.</p>
                    </div>
                </div>
            </div> */}
        
            {/* <button
                className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0" type="button" data-bs-target="#carouselDarkVariant" data-bs-slide="prev">
                <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0" type="button" data-bs-target="#carouselDarkVariant" data-bs-slide="next">
                <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button> */}
        {/* </div> */}
    </>
  )
}

export default Slider
 