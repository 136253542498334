
import React from 'react';
import { Outlet } from 'react-router-dom';

import { Navigation, Footer } from '../components/default';


const StaticRoute = () => {

  return (
    <>
      <Navigation />
        <Outlet />
      <Footer />
    </>
  )
};

export default StaticRoute;
 